import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./styles/global.css";

Vue.use(ElementUI);
import $ from "jquery";
Vue.prototype.$ = $;

//日期处理
import moment from "moment";
Vue.prototype.$moment = moment;

// 导入api接口
import api from "./api";
Vue.prototype.$api = api;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
