/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios';
import store from '../store/index';
import {
  Message
} from 'element-ui'

// 创建axios实例
let request = axios.create({
  timeout: 1000 * 120
});

//环境判断
if (process.env.NODE_ENV == 'development') {
  // request.defaults.baseURL = '/api';开发环境
  request.defaults.baseURL = 'http://smartmicrochain.cn:8086';
} else if (process.env.NODE_ENV == 'production') {
  //正式环境
  // request.defaults.baseURL = window.BASE_URL;
  request.defaults.baseURL = 'http://smartmicrochain.cn:8086';
}
// 设置post请求头
// request.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';//'application/json';//application/json;charset=UTF-8;
// request.defaults.baseURL = '/api'
request.defaults.headers['Content-Type'] = 'application/json';
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
request.interceptors.request.use(
  config => {
    // config.headers['Content-Type'] = "application/json";
    // config.data = JSON.stringify(config.data);
    const token = store.state.userInfo.token;
    // token && (config.headers.Authorization = token);
    if (token) {
      config.headers['GREENHOUSE-Authorization'] = token;
    }
    return config;
  },
  error => Promise.error(error))

// 响应拦截器
request.interceptors.response.use(
  // 请求成功
  // res => response.status === 200 ? Promise.resolve(response) : Promise.reject(response),
  (response) => {
    // if (response.data && response.data.code !== "0000") {
    //   Message.error(response.data.message);
    //   return Promise.reject(response.data);
    // }
    return response.status === 200 ? Promise.resolve(response) : Promise.reject(response);
  },
  // 请求失败
  error => {
    const {
      response
    } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response);
      return Promise.reject(response);
    } else {
      Message.error("网络出了点问题!");
      return Promise.reject(error);
    }
  });

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (response) => {
  // 状态码判断
  switch (response.status) {
    case 401:
      break;
    case 403:
      Message.error("登录过期，请重新登录!");
      store.commit("setUser", {})
      setTimeout(() => {
        router.replace({
          path: '/login',
        });
      }, 1000);
      break;
      // 404请求不存在
    case 404:
      Message.error("网络请求不存在!");
      break;
      // 其他错误，直接抛出错误提示
      // 500接口异常
    default:
      Message.error("服务异常！请联系管理员");
      console.log(response.data.message);
      break;
  }
}
export default request;
