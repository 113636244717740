/**
 * api接口的统一出口
 */
//登录
import home from '@/api/home';

//导出接口
export default {
  home,
}
