import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: "/agrPage",
  },
  {
    path: "/agrPage",
    name: "AgriPage",
    component: () => import("../views/AgriPage.vue"),
    meta: {
      title: "",
      active: "/agriPage"
    }
  },
  {
    path: "/appPage",
    name: "AppPage",
    component: () => import("../views/appPage/index.vue"),
    meta: {
      title: "",
      active: "/appPage"
    }
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/home.vue"),
    meta: {
      title: "",
      active: "/home"
    }
  },
  {
    path: "*",
    redirect: {
      path: "/agrPage"
    }
  }
];

const router = new VueRouter({
  routes,
});

export default router;
