import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: {},
    query: {},
  },
  getters: {},
  mutations: {
    setUser(state, params) {
      state.userInfo = params;
    },
    setQuery(state, params) {
      state.query = params;
    },
  },
  actions: {},
  modules: {},
});
